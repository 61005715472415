<template>
  <div :class="['event-participants-card', 'card', {checked: isDelete}]" @click="deleteUser">
    <div class="card__left">
      <img class="content__left-avatar" :src="getUserAvatar(data.avatarSrc.fileSrc)" alt="">
      <div class="card__left-info">
        <p class="card__left-info-nickname">{{ getNickname }}</p>
        <p class="card__left-info-role"><img :src="getUserRole(data.role).icon" alt="">{{ $t(getUserRole(data.role).text) }}</p>
        <div class="card__left-info-location">
          <img src="../../../../assets/svg/icon-location.svg" alt="" />
          {{ data?.city || data?.region?.name }}
        </div>
      </div>
    </div>
    <div class="card__right">
      <img src="../../../../assets/svg/icon-location.svg" alt="" />
      {{ data?.city || data?.region?.name }}
    </div>
  </div>
</template>

<script>
import {getUserAvatar, getUserRole} from "@/utils/user";
import {mapMutations} from "vuex";

export default {
  name: "EventParticipantsCard",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isDelete: false
    }
  },

  methods: {
    ...mapMutations(['setDeletedEventUsers']),
    getUserAvatar,
    getUserRole,
    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },

    deleteUser () {
      this.isDelete = !this.isDelete
      const payload = {
        id: this.data.id,
        val: this.isDelete
      }
      this.setDeletedEventUsers(payload)
    }
  },

  computed: {
    getNickname() {
      return this.data.role === 'Inspector' || this.data.role === 'Lawyer' ? this.getUserName(this.data.lastName, this.data.firstName, this.data.middleName) : '' ||
      this.data.role === 'Company' ? this.data.companyName : '' ||
      this.data.role === 'Government' ? this.data.companyName : this.data.role === 'Active' ? this.data.login : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 135px;
  max-width: 1000px;
  width: 100%;
  background: #FFFFFF;
  padding: 12px 80px 11px 20px;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 200px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: .3s;

  &.checked {
    border: 1px solid #CE2121;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 114.583px;
      border: 1px solid #CE2121;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-nickname {
        color: #1B1A1F;
        font-size: 16px;
      }

      &-role {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #1B1A1F;
        font-size: 14px;
        line-height: 120.2%;
        font-family: "Gotham Pro Regular";

        img {
          width: 16px;
          height: 16px;
          border-radius: inherit;
          border: none;
        }
      }

      &-location {
        display: none;
        align-items: center;
        gap: 5px;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        color: #343432;
        font-family: "Gotham Pro Regular";
        white-space: nowrap;

        @media (max-width: 768px) {
          display: flex;
        }

        @media (max-width: 450px) {
          white-space: normal;
        }

        img {
          width: 16px;
          height: 16px;
          border: none;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1B1A1F;
    font-size: 16px;
    line-height: 120.2%;
    font-family: "Gotham Pro Regular";

    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
