<template>
  <div class="event-participants__right">
    <div class="event-participants__right-search">
      <ui-input search :placeholder="$t('search')" v-model="search" @input="updateUsersList"/>
      <div class="event-participants__right-search-icons">
        <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.value === sortBy.value }]"
                  :key="key"
              >
                {{ item.text }}
                <img v-if="item.value === sortBy.value" src="@/assets/svg/red-check.svg" alt="">
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        @submit="updateUsersList"
        @reset="resetUpdate"
    >
<!--      <ui-select-->
<!--          v-model="searchObj.region"-->
<!--          label="Регион"-->
<!--          :options="getRegions"-->
<!--      />-->
      <ui-select
          :label="$t('role')"
          v-model="searchObj.role"
          :options="getRoleOptions"
      />
    </search-filter>
    <p class="event-participants__right-count">{{ $t('participants-open-length', {from: totalCount,to: totalMeta}) }}</p>
    <ui-loader v-model="visibleLoader" />
    <div class="event-participants__right-cards" v-if="!visibleLoader">
      <event-participants-card v-for="(item, key) in getEventUsers" :data="item" :key="key"/>
    </div>
    <div v-if="!getEventUsers.length && !visibleLoader" class="empty">
      {{ $t('empty') }}
    </div>
    <ui-button v-if="visibleLoadMore && !visibleLoader" color="white" @click="loadMore">
      {{ $t('show-more') }}
    </ui-button>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import EventParticipantsCard from "@/components/template/event/event-participants/EventParticipantsCard.vue";
import UiMenu from "@/components/ui/UiMenu.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import SearchFilter from "@/components/common/SearchFilter.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "EventParticipants",
  components: {UiButton, UiLoader, SearchFilter, UiSelect, UiMenu, EventParticipantsCard, UiInput},
  data () {
    return {
      search: '',
      visibleFilter: false,
      visibleSort: false,
      sortData: [
        { text: this.$t('by-region'), value: 'region' },
        // { text: 'По роли', value: '' }
      ],
      getRoleOptions: [
        { name: this.$t('inspector'), value: 'Inspector' },
        { name: this.$t('business'), value: 'Company' },
        { name: this.$t('public-authority'), value: 'Government' },
        { name: this.$t('eco-lawyer'), value: 'Lawyer' },
        { name: this.$t('eco-activist'), value: 'Active'}
      ],
      sortBy: '',
      searchObj: {
        region: '',
        role: ''
      },
      visibleLoader: true,
      visibleLoadMore: true,
      totalMeta: 0,
      totalCount: 0
    }
  },
  methods: {
    ...mapActions(['eventUsers']),
    ...mapMutations(['setEventUsers']),

    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
      this.updateUsersList()
    },

    callApi () {
      const payload = {
        eventId: this.$route.params.id,
        skip: 0,
        take: 21,
      }
      this.eventUsers(payload).then((res) => {
        this.totalMeta = res.meta.total
        this.totalCount = res.data.length
        this.setEventUsers(res.data)
        this.visibleLoader = false
        if (res.meta.total === res.data.length) {
          this.visibleLoadMore = false
        }else {
          this.visibleLoadMore = true
        }
      })
    },

    updateUsersList() {
      this.visibleLoader = true

      const payload = {
        eventId: this.$route.params.id,
        skip: 0,
        take: 21,
        search: this.search || null,
        roles: this.searchObj.role ? [this.searchObj.role.value] : null,
        sort: this.sortBy?.value || null,
        order: this.sortBy?.value ? 'ASC' : null
      }

      this.eventUsers(payload).then((res) => {
        this.totalCount = res.data.length
        this.totalMeta = res.meta.total
        this.setEventUsers(res.data)
        this.visibleLoader = false
        if (res.meta.total === this.getEventUsers.length) {
          this.visibleLoadMore = false
        }else {
          this.visibleLoadMore = true
        }
      })
    },

    loadMore() {
      const payload = {
        eventId: this.$route.params.id,
        skip: this.getEventUsers.length,
        take: 21,
        search: this.search || null,
        roles: this.searchObj.role ? [this.searchObj.role.value] : null,
        sort: this.sortBy?.value || null,
        order: this.sortBy?.value ? 'ASC' : null
      }

      this.eventUsers(payload).then((res) => {
        this.totalCount += res.data.length
        this.setEventUsers(this.$store.state.eventsStore.eventUsers.concat(res.data))
        if (res.meta.total === this.getEventUsers.length) {
          this.visibleLoadMore = false
        }else {
          this.visibleLoadMore = true
        }
      })
    },

    resetUpdate() {
      this.search = ''
      this.searchObj.role = ''
      this.sortBy = ''
      this.updateUsersList()
    }
  },
  computed: {
    ...mapGetters(['getRegions', 'getEventUsers']),
    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },
    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },
  },

  mounted () {
    this.callApi()
  }
}
</script>

<style lang="scss" scoped>
.event-participants__right {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  &-count {
    color: #9A9A9A;
    font-family: 'Gotham Pro Medium';
    font-size: 14px;
    font-weight: 400;
  }

  &-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-search {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;

    @media (max-width: 992px) {
      gap: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      padding: 0;
      border: none;
    }

    &-icons {
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 992px) {
        gap: 10px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}
::v-deep .filter__row {
  display: grid !important;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column;
  }
}
</style>
